import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import PropTypes from "prop-types";
import Arrow from "../icons/arrow";

const Intro = ({
  introImage,
  title,
  text,
  link,
  linkText,
  introToggle,
  theme,
}) => {
  const isImageShown = introToggle === undefined ? false : introToggle;
  return (
    <div className={`${theme ? theme : "bg-[#F7F7F7]"} md:h-[590px] relative`}>
      <div
        className={`md:absolute h-[250px] top-0 w-full md:w-1/2 md:h-full ${
          isImageShown ? "left-0" : "right-0"
        }`}
      >
        <GatsbyImage
          image={getImage(introImage)}
          className={"w-full h-full object-cover"}
          layout="fullWidth"
        />
      </div>

      <div
        className={`mx-auto max-w-screen-xl w-full px-6 h-full flex flex-col justify-center ${
          isImageShown ? " items-end" : ""
        }`}
      >
        <div
          className={`flex flex-col justify-center w-full h-full py-12 md:py-0 md:w-1/2 ${
            isImageShown ? "md:pl-14" : " md:pr-14"
          }`}
        >
          {title && (
            <h2
              className={`text-blue ${
                text || link
                  ? "mb-4 md:mb-8"
                  : "py-4 md:py-0 text-xl md:text-3xl md:leading-10"
              }`}
            >
              {title}
            </h2>
          )}
          {text && <div>{text}</div>}
          {link && (
            <a
              href={link}
              target="_blank"
              rel="noreferrer nofollow noopener"
              className={`flex items-center mt-10 text-blue`}
            >
              {linkText}
              <span className={`inline-block ml-8`}>
                <Arrow />
              </span>
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

Intro.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  text: PropTypes.string,
  link: PropTypes.string,
  linkText: PropTypes.string,
  introToggle: PropTypes.bool,
};

export default Intro;
