import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import PropTypes from 'prop-types';
const Hero = ({ image, title, link, linkText }) => {
  return (
    <div className={`h-[250px] md:h-[600px] relative mb-12 md:mb-20`}>
      <GatsbyImage
        image={getImage(image)}
        className={"w-full h-full"}
        layout="fullWidth"
      />
      <div className={`absolute bottom-0 pb-4 md:pb-16 w-full`}>
        <div className={`mx-auto max-w-screen-xl w-full px-6`}>
          <div className={`max-w-4xl`}>
            {title && <h1 className="text-white lg:pr-12">{title}</h1>}

            {link && linkText && (
              <a
                href={link}
                className={`mt-2 md:mt-6 inline-block py-1 md:py-2.5 px-6 font-medium rounded-[10px] bg-green border-0 text-white  focus:shadow-none transition`}
              >
                {linkText}
              </a>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
Hero.propTypes = {
    image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    title: PropTypes.string,
    link: PropTypes.string,
    linkText: PropTypes.string,
  };

  
export default Hero
