import * as React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import Hero from "../components/Hero";
import Intro from "../components/Intro";
import Seo from "../components/Seo";
import Quotes from "../components/Quotes";

const BookNowPageTemplate = ({
  hero,
  intro,
  quotes,
}) => {

  return (
    <div>
      <Hero
        image={hero.image}
        title={hero.title}
        link={hero.link}
        linkText={hero.linkText}
      />
      <div id="training-about" className="relative -top-24"></div>
      <Intro
        introImage={intro.introImage}
        title={intro.title}
        text={intro.text}
        link={intro.link}
        linkText={intro.linkText}
        introToggle={intro.introToggle ? intro.introToggle : false}
      />
      <Quotes quotes={quotes} />
    </div>
  );
};

const BookPage = ({ data }) => {
  const frontmatter = data.markdownRemark.frontmatter;

  return (
    <Layout>
      <Seo title={frontmatter.hero.title} description={frontmatter.intro.text} />
      <BookNowPageTemplate
        hero={frontmatter.hero}
        intro={frontmatter.intro}
        quotes={frontmatter.quotes}
      />
    </Layout>
  );
};

BookPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export const bookPageQuery = graphql`
  query BookPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        hero {
          title
          link
          linkText
          image {
            childImageSharp {
              gatsbyImageData(quality: 100, layout: FULL_WIDTH)
            }
          }
        }
        intro {
          title
          text
          link
          linkText
          introToggle
          introImage {
            childImageSharp {
              gatsbyImageData(quality: 100, layout: FULL_WIDTH)
            }
          }
        }
        quotes {
          quote
          author
        }
      }
    }
  }
`;
export default BookPage;